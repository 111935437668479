<template>
  <div>
    <v-container fluid class="py-8">
      <Notify></Notify>
      <v-row>
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light mb-5">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{
                  $t("approach.Approach Index Paragraph")
                }}</v-toolbar-title>
              </v-app-bar>
              <Table
                :loading="loading"
                @action="action_paragraph"
                :btns="btns_paragraph"
                :headers="header_paragraph"
                :items="approach_paragraph"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <Modal :data="modal_data"></Modal>
    </v-container>
  </div>
</template>
<script>
import Table from "../../Components/Table.vue";
import Notify from "../../Components/New/Notify.vue";
import Modal from "../../Components/New/Modal.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("cms");
export default {
  name: "Approach-Home-Page",
  components: {
    Table,
    Notify,
    Modal,
  },
  data() {
    return {
      modal_data: {
        size: "500px",
        title: "",
      },
      loading: true,
      form_data_paragraph: {
        title: "",
        body: "",
      },
      form_style_paragraph: [
        {
          col: "12",
          type: "text",
          label: this.$i18n.t("cycle.title"),
          error: null,
          value_text: "title",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "textarea",
          rows: 5,
          label: this.$i18n.t("news.body"),
          error: null,
          value_text: "body",
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
      ],
      btns_paragraph: [
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "cms-approach-paragraph-home-update",
        },
      ],
      header_paragraph: [
        {
          text: this.$i18n.t("quote.section"),
          value: "section",
          align: "center",
        },
        { text: this.$i18n.t("cycle.title"), value: "title", align: "center" },
        { text: this.$i18n.t("news.body"), value: "body", align: "center" },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapState(["approach_paragraph"]),
  },
  methods: {
    ...mapActions(["getParagraphApproach"]),
    get_approach() {
      this.getParagraphApproach().then(
        () => {
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch("form/setNotify", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
        }
      );
    },
    action_paragraph(id, name, item) {
      if (name == "edit") {
        this.edit_paragraph(item);
      }
    },
    edit_paragraph(item) {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title =
        this.$i18n.t("approach.Edit approach") + " # " + item.title;
      this.$store.dispatch("form/setData", this.form_style_paragraph);
      this.form_data_paragraph.title = item.title;
      this.form_data_paragraph.body = item.body;
      this.$store.dispatch("form/setFormData", this.form_data_paragraph);
      this.$store.commit("form/INFO_CALL", {
        name: "cms/editParagraphApproach",
        id: item.id,
      });
    },
  },
  mounted() {
    this.get_approach();
    document.title = this.$i18n.t("sidebar.Approach");
  },
};
</script>
